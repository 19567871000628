<template>
  <b-container fluid>
    <b-row class="p-3">
      <b-col>
        <b-row class="d-flex" align-v="center">
          <h1 class="mr-5">Dashboard</h1>
        </b-row>
      </b-col>
    </b-row>
    <b-row align-h="center" class="p-3">
      <b-col md="3" xl="2">
        <b-card>
          <div class="d-flex">
            <div style="flex:1;">
              <span>
                <i class="fas fa-flag fa-2x" />
              </span>
            </div>
            <div class="text-right">
              <h2>{{ data.events }}</h2>
              <p>Workouts</p>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col md="3" xl="2">
        <b-card>
          <div class="d-flex">
            <div style="flex:1;">
              <span>
                <i class="fas fa-map-marker-alt fa-2x" />
              </span>
            </div>
            <div class="text-right">
              <h2>{{ data.locations }}</h2>
              <p>Venues</p>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col md="3" xl="2">
        <b-card>
          <div class="d-flex">
            <div style="flex:1;">
              <span>
                <i class="fas fa-user fa-2x" />
              </span>
            </div>
            <div class="text-right">
              <h2>{{ data.users }}</h2>
              <p>Users</p>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'dashboard',
  data () {
    return {
      data: {
        events: null,
        locations: null,
        users: null
      }
    }
  },
  methods: {
    async getDashboard () {
      const url = `${this.$config.apiUrl}/dashboard`
      const res = await this.axios.get(url)
      this.data = res.data
    }
  },
  created () {
    this.getDashboard()
  }
}
</script>

<style>
.card {
  margin-bottom: 1rem;
}
</style>
